<span></span>
<ion-app
  *ngrxLet="{ hasPremium: hasSubscription$, bar: bar$, user: user$ } as vm"
  dir="{{ textDir }}"
>
  <ion-split-pane contentId="menu-content">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <img src="/assets/images/logo-sidemenu.jpg" />
          <ion-row class="user-details-wrapper">
            <ion-col size="3" *ngIf="vm.bar?.photoURL">
              <app-aspect-ratio [ratio]="{ w: 1, h: 1 }">
                <ion-avatar>
                  <img class="user-avatar" src="{{ vm.bar?.photoURL }}" />
                </ion-avatar>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="9">
              @if(vm.bar?.name?.length ?? 0 < 16) {
              <h5 class="user-name">{{ vm.bar?.name }}</h5>
              } @else {
              <h3 class="user-name">{{ vm.bar?.name }}</h3>
              }
              <!-- <ion-note>
                <ion-button
                  fill="outline"
                  size="small"
                  color="secondary"
                  (click)="signOut()"
                  >Sign out</ion-button
                >
              </ion-note> -->
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>

        <ion-list id="inbox-list">
          <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <ion-item
              (click)="gotTo(vm.hasPremium, p, i)"
              routerDirection="root"
              button
              lines="none"
              detail="false"
              [class.selected]="selectedIndex == i"
            >
              <ion-icon *ngIf="p.src" slot="start" [src]="p.src"></ion-icon>
              <ion-icon
                *ngIf="!p.src"
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
              ></ion-icon>
              <ion-label>
                {{ p.title }}
                <ion-badge color="danger" *ngIf="p.badge">{{
                  p.badge
                }}</ion-badge>
                 <p *ngIf="p.title == 'Subscription' && vm.hasPremium == true">
                  Premium
                </p>
                <p *ngIf="p.title == 'Subscription' && vm.hasPremium == false">
                  Free
                </p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!-- <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; let i = index"
          >
            <ion-item
              *ngIf="p.profileItem"
              (click)="gotTo(vm.hasPremium, p, i)"
              routerDirection="root"
              button
              lines="none"
              detail="false"
              [class.selected]="selectedIndex == i"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
                class="plan-icon"
              ></ion-icon>
              <ion-label>
                <h3>{{ p.title }}</h3>
                <p *ngIf="p.title == 'Subscription' && vm.hasPremium == true">
                  Premium
                </p>
                <p *ngIf="p.title == 'Subscription' && vm.hasPremium == false">
                  Free
                </p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle> -->
          <!-- <ion-item
            target="blank"
            [attr.href]="thriveCartPurchaseLink"
            *ngIf="thriveCartPurchaseLink && vm.hasPremium == false"
          >
            Purchase
          </ion-item> -->
          <ion-item
            button
            href="https://docs.barhelper.app/v/english/getting-started"
            target="_blank"
          >
            <ion-icon name="help-outline" slot="start"></ion-icon>
            <ion-label>Help</ion-label>
          </ion-item>
         <ion-item button (click)="signOut()">
            <ion-icon name="log-out" slot="start"></ion-icon>
            <ion-label>Sign out</ion-label>
          </ion-item>

          <ion-list-header lines="none">
            <ion-label>Account</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-avatar
              slot="start"
              *ngIf="vm.user?.photoURL"
              class="user-avatar"
            >
              <img src="{{ vm.user?.photoURL }}" />
            </ion-avatar>
            <ion-label>
              <p>
                {{ vm.user?.displayName }}<br />
                {{ vm.user?.email }}
              </p>
            </ion-label>
          </ion-item>

        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-text
            ><small>Version: {{ version }}</small></ion-text
          >
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
  <span *ngrxLet="beverages$ as i"></span>
  <span *ngrxLet="barSettings$ as i"></span>
  <span *ngrxLet="locations$ as i"></span>
  <span *ngrxLet="units$ as i"></span>
  <span *ngrxLet="taps$ as i"></span>
  <span *ngrxLet="tapError$ as i"></span>
  <span *ngrxLet="kegs$ as i"></span>
  <span *ngrxLet="externalApiSettings$ as i"></span>
  <span *ngrxLet="plaatos$ as i"></span>
  <span *ngrxLet="kegtrons$ as i"></span>
  <span *ngrxLet="customKegMons$ as i"></span>
  <span *ngrxLet="statistics$ as i"></span>
  <span *ngrxLet="subscription$ as i"></span>
  <span *ngrxLet="beerStyles$ as i"></span>
  <span *ngrxLet="fonts$ as i"></span>
  <span *ngrxLet="ontapAdvanced$ as i"></span>
  <span *ngrxLet="ontapTemplates$ as i"></span>
  <span *ngrxLet="notifications$ as i"></span>
  <span *ngrxLet="readNotifications$ as i"></span>
  <span *ngrxLet="unreadNotifications$ as i"></span>
  <span *ngrxLet="stripeProducts$ as i"></span>

</ion-app>
