import { Subscription } from '@invertase/firestore-stripe-payments';
import { createAction, props } from '@ngrx/store';

export const clearAllItems = createAction('[DalHelper] Clear All Stripe Subscriptions');
export const setLoadingManager = createAction('[DalHelper] Set Loading in Stripe Subscriptions', props<{ loading: boolean }>());

export const subscribe = createAction('[Module Component] Subscribe To Stripe Subscription', props<{ priceId: string }>());

export const loadItem = createAction('[Module Component] Load Stripe Subscription', props<{ id: string }>());
export const loadItemSuccess = createAction('[Stripe API] Load Stripe Subscription Success', props<{ item: Subscription }>());
export const loadItemFailure = createAction('[Stripe API] Load Stripe Subscription Failure', props<{ error: any }>());

export const loadItems = createAction('[Module Component] Load Stripe Subscriptions');
export const loadItemsSuccess = createAction('[Stripe API] Load Stripe Subscriptions Success', props<{ items: Subscription[] }>());
export const loadItemsFailure = createAction('[Stripe API] Load Stripe Subscriptions Failure', props<{ error: any }>());

export const loadAllItems = createAction('[Module Component] Load All Stripe Subscriptions', props<{ id: string }>());
export const loadAllItemsSuccess = createAction('[Stripe API] Load All Stripe Subscriptions Success', props<{ items: any[] }>());
export const loadAllItemsFailure = createAction('[Stripe API] Load All Stripe Subscriptions Failure', props<{ error: any }>());

