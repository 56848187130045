
import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { collection, Firestore, query, where, limit, getDocs, doc, getDoc, Timestamp, collectionData, DocumentReference } from '@angular/fire/firestore';
import { map, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DalStripeSubscriptionsService {


  constructor(
    private firestore: Firestore) {
  }



  getAllItems(uid: string): Observable<Subscription[]> {
    const path = `${dbConst.stripeCustomers}/${uid}/subscriptions`;

    const listRef = collection(this.firestore, path);

    return collectionData(listRef, { idField: 'id' })
      .pipe(
        map(changes => {

          return changes.map((a: any) => {
            const data = a as any;
            data.prices = [];
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())

            //Get the last element of the path
            Object.keys(data).filter(key => data[key] instanceof DocumentReference)
              .forEach(key => data[key] = data[key].path.split('/').pop());




            return { ...data };
          });
        })
      ) as Observable<Subscription[]>;
  }





  async getItemsOnce(userId: string): Promise<any[]> {
    const path = `${dbConst.stripeCustomers}/${userId}/${dbConst.subscriptions}`;

    const listRef = collection(this.firestore, path);
    const q = query(listRef);
    // const q = query(listRef, limit(10));
    const querySnap = await getDocs(q);

    const items: any[] = [];

    querySnap.forEach((doc) => {
      const item = doc.data();
      if (item) {
        const data = item as any;
        Object.keys(data).filter(key => Boolean(data[key]?.seconds))
          .forEach(key => {
            if (data[key].seconds) {
              const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
              data[key] = date.toDate();
            }
          });
        Object.keys(data).filter(key => data[key] instanceof Timestamp)
          .forEach(key => data[key] = data[key].toDate())


        data['id'] = doc.id;
        items.push(data as any);
      }
    });

    return items;

  }
}
