import { Injectable } from '@angular/core';
import { getApp } from 'firebase/app';
import { Subscription, createCheckoutSession, getCurrentUserSubscriptions, getStripePayments } from '@invertase/firestore-stripe-payments';
import { collection, collectionData, doc, DocumentReference, Firestore, getDoc, getFirestore, setDoc, Timestamp } from '@angular/fire/firestore';
import { environment } from 'environments/environment';
import { dbConst } from '../dal/databaseConstants';
import { initializeApp } from '@angular/fire/app';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeSubscriptionService {

  private firestore: Firestore;


  constructor() {
    const app = initializeApp(environment.firebase);
    this.firestore = getFirestore(app);
  }


  async subscribe(priceId: string) {
    const app = getApp();

    const payments = getStripePayments(app, {
      productsCollection: "stripeProducts",
      customersCollection: "stripeCustomers",
    });

    const session = await createCheckoutSession(payments, {
      price: priceId,
    });
    window.location.assign(session.url);
  }


  async getItemsFromCurrentUser(): Promise<Subscription[]> {
    const app = getApp();
    const payments = getStripePayments(app, {
      productsCollection: "stripeProducts",
      customersCollection: "stripeCustomers",
    });

    const subs = await getCurrentUserSubscriptions(payments, { status: 'active' });


    return subs;
  }

  async addSubscription(
    uid: string,
    email: string,
    stripeCusromerId: string,
    stripeSubscriptionId: string,
    prices: any[],
    price: any,
    product: string,
    endDate: Date) {
    const custDocRef: DocumentReference = doc(this.firestore, `${dbConst.stripeCustomers}/${uid}`);
    const custDocSnap = await getDoc(custDocRef);

    if (!custDocSnap.exists()) {
      await setDoc(custDocRef, {
        email: email,
        stripeId: stripeCusromerId,
        stripeLink: `https://dashboard.stripe.com/test/customers/${stripeCusromerId}`
      });
    }
    const subDocRef = doc(this.firestore, `${dbConst.stripeCustomers}/${uid}/subscriptions/${stripeSubscriptionId}`);
    const subDocSnap = await getDoc(subDocRef);


    const pricesArray = prices.map(p => doc(this.firestore, `${dbConst.stripeProducts}/${p.product}/prices/${p.id}`) as DocumentReference);
    const priceDocRef = doc(this.firestore, `${dbConst.stripeProducts}/${price.product}/prices/${price.id}`) as DocumentReference;
    const prodDocRef = doc(this.firestore, `${dbConst.stripeProducts}/${price.product}`) as DocumentReference;

    const subData = {
      cancel_at: null,
      cancel_at_period_end: false,
      canceled_at: null,
      created: new Date(),

      current_period_start: new Date(),
      current_period_end: endDate,
      ended_at: null,
      id: stripeSubscriptionId,
      metadata: null,
      price: priceDocRef,
      prices: pricesArray,
      product: prodDocRef,
      quantity: 1,
      role: null,
      status: 'active',
      stripe_link: 'https://dashboard.stripe.com/test/subscriptions/' + stripeSubscriptionId,
      trial_end: null,
      trial_start: null,
      uid: uid,
    }

    if (!subDocSnap.exists()) {
      await setDoc(subDocRef, {
        ...subData,
        prices: pricesArray,
        price: priceDocRef
      });
    }

  }
}



/*

  cancel_at: toNullableUTCDateString(data.cancel_at),
            cancel_at_period_end: data.cancel_at_period_end,
            canceled_at: toNullableUTCDateString(data.canceled_at),
            created: toUTCDateString(data.created),
            current_period_start: toUTCDateString(data.current_period_start),
            current_period_end: toUTCDateString(data.current_period_end),
            ended_at: toNullableUTCDateString(data.ended_at),
            id: snapshot.id,
            metadata: (_a = data.metadata) !== null && _a !== void 0 ? _a : {},
            price: data.price.id,
            prices,
            product: data.product.id,
            quantity: (_b = data.quantity) !== null && _b !== void 0 ? _b : null,
            role: (_c = data.role) !== null && _c !== void 0 ? _c : null,
            status: data.status,
            stripe_link: data.stripeLink,
            trial_end: toNullableUTCDateString(data.trial_end),
            trial_start: toNullableUTCDateString(data.trial_start),
            uid: snapshot.ref.parent.parent.id,


*/
