import { Injectable } from '@angular/core';
import { getApp } from 'firebase/app';
import { getPrices, getProducts, getStripePayments, Price } from '@invertase/firestore-stripe-payments';

@Injectable({
  providedIn: 'root'
})
export class StripeProductService {


  constructor() {
  }


  async getProductsAndPrices(): Promise<any> {

    const app = getApp();
    const payments = getStripePayments(app, {
      productsCollection: "stripeProducts",
      customersCollection: "stripeCustomers",
    });

    const products = await getProducts(payments);
    const allPrices: Price[] = [];
    for (let product of products) {
      const prices = await getPrices(payments, product.id);
      allPrices.push(...prices);
    }
    return { products, prices: allPrices };
  }


}
