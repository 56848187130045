import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { StripeSubscriptionService } from 'app/services/stripe/stripesubscription.service';
import { DalStripeSubscriptionsService } from '../../services/dal/stripesubscriptions.service';

@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private service: StripeSubscriptionService,
    private dalStripeSubscriptionsService: DalStripeSubscriptionsService,
    private stripSubscriptionService: StripeSubscriptionService,
    private toastService: ToastService
  ) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        switchMap(() => this.service.getItemsFromCurrentUser()),

        filter((items) => Boolean(items)),
        map(items => {
          return fromActions.loadItemsSuccess({ items: items })
        }),
        catchError((error) => {
          this.toastService.error(error);
          return of(fromActions.loadItemsFailure({ error }));
        })
      );
  });

  loadAllItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadAllItems),
        switchMap((action) => this.dalStripeSubscriptionsService.getAllItems(action.id)),

        map(items => {
          return fromActions.loadAllItemsSuccess({ items: items })
        }),
        catchError((error) => {
          this.toastService.error(error);
          return of(fromActions.loadItemsFailure({ error }));
        })
      );
  });



  subscribe$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.subscribe),
        switchMap((action) => this.stripSubscriptionService.subscribe(action.priceId))
      );
  }, { dispatch: false });



}
