import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on, Store } from '@ngrx/store';
import * as Actions from './actions'
import { IStripeProduct, IStripeProductVM } from 'app/models/stripeProduct';
import * as fromStripePrice from '../stripePrice';
import { inject } from '@angular/core';
import { Price } from '@invertase/firestore-stripe-payments';
export interface State extends EntityState<IStripeProduct> {
  selectedId: string;
  loading: boolean;
  error: string;
}

export function sortByName(a: IStripeProduct, b: IStripeProduct): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IStripeProduct): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IStripeProduct> = createEntityAdapter<IStripeProduct>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: '',
  loading: false,
  error: ''
});

export const stateName = 'stripeProduct';

export const reducer = createReducer<State>(
  initialState,
  on(Actions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(Actions.loadItems, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(Actions.loadItemsSuccess, (state, { items }) => {
    const privateState = {
      ...state,
      loading: false,
    };
    return adapter.setAll(items, privateState);
  }),
  on(Actions.loadItemFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      entities: {},
      error: action.error
    };
  }),

);


//export const getSelectedPlaatoId = (state: PlaatoState) => state.selectedPlaatoId;

// get the selectors
const {
  // selectIds,
  selectEntities,
  selectAll,
  // selectTotal,
} = adapter.getSelectors();


export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(selectAllEntities, getCurrentId, (entities, id) => {
  if (!!id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);
export const isLoading = createSelector(getState, (state) => state.loading);
export const selectAllProductsWithPrices = createSelector(
  selectAllItems,
  fromStripePrice.selectAllItems,
  (products: IStripeProduct[], prices: Price[]) => {
    const retProducts: IStripeProductVM[] = [];

    products.forEach(item => {
      const retItem: IStripeProductVM = {
        ...item,
        prices: prices.filter(price => price.product === item.id)
      };
      retProducts.push(retItem);
    });
    return retProducts;
  });

